<style lang="scss" scoped>
.contact-list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: .10rem .26rem;
  font-size: .14rem;
  li {
    display: flex;
    color: #303133;
    line-height: .22rem;
    margin-top: .15rem;
    margin-bottom: .22rem;
    &:first-child {
      margin-top: 0;
    }
  }
  &__method {
    width: 50%;
    max-width: 1.80rem;
    margin-right: .20rem;
    @include els();
  }
  button {
    @include button();
  }
}
</style>

<template>
  <div class="component-root">
    <!-- 咨询渠道推荐 -->
    <div class="message-header">
      <em class="icon-dot"></em>咨询渠道推荐
    </div>
    <!-- <div class="contact-list" v-html="message.content">
    </div> -->
    <ul class="contact-list">
      <li
        v-for="(item, index) in message.content"
        :key="index"
      >
        <div v-html="item.name" class="contact-list__method">{{item.name}}</div>
        <button v-html="item.value">{{item.value}}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BubbleContact',
  props: {
    message: Object
  },
  computed: {
    list () {
      const content = this.message.content
      const items = content.split(/[\s]+/)
      const list = []

      items.forEach(item => {
        const tmp = item.split(':')
        const name = tmp.shift()
        const contact = tmp.join(':')

        list.push({
          name,
          contact
        })
      })

      return list
    }
  }
}
</script>
